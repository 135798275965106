/// <reference types="@types/googlemaps" />

export type TrulyLicensedState = 'TX' | 'UT' | 'FL' | 'IL';

export function isLicensedState(x: string): x is TrulyLicensedState {
    return x === 'TX' || x === 'UT' || x === 'FL' || x === 'IL';
}

export function uniqueID() {
    return '_' + Math.random().toString(36).substr(2, 9);
}

export interface LocationData {
    locationId: string;
    title: string;
    subtitle?: string;
    streetAddress: string;
    cityAddress: string;
    tollFreeNumberVersion: string;
    tollFreeTrulyVersion: string;
    officeNumberDirect: string;
    faxNumber: string;
    directionsLinkURL: string;
    coordinates: google.maps.LatLngLiteral;
    zoom: number;
    appointmentBool?: boolean;
    showMapPlaceholder?: boolean;
}

export const allLocations: LocationData[] = [
    {
        locationId: uniqueID(),
        title: 'The Loop, Chicago',
        streetAddress: '17 North State Street, Suite 1720',
        cityAddress: 'Chicago, IL 60602',
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: '312-488-4667',
        faxNumber: '312-971-5687',
        directionsLinkURL: "https://www.google.com/maps/dir//17+N+State+St,+Chicago,+IL+60602",
        coordinates: { lat: 41.88269942243089, lng: -87.62743076283765 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: "Gurnee, Illinois",
        streetAddress: "1761 N. Dilleys Road, Bellewater Suites Conference Room & Suite 108-4",        
        cityAddress: "Gurnee, IL 60031",        
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "312-488-4667",
        faxNumber: "312-971-5687",
        directionsLinkURL: "https://www.google.com/maps/dir//1761+N+Dilleys+Rd,+Gurnee,+IL+60031",
        coordinates: { lat: 42.38359535435089, lng: -87.9399211012792 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: "Dallas Metro (HQ)",
        streetAddress: "2901 N Dallas Parkway, Suite 130",
        cityAddress: "Plano, TX 75093",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "214 261-9943",
        faxNumber: "972-525-8857",
        directionsLinkURL: "https://www.google.com/maps/dir//Truly+Title,+Inc.,+Dallas+Parkway,+Plano,+TX,+USA",
        coordinates: { lat: 33.04009472658888, lng: -96.83116453330359 },
        zoom: 14,
    },
    {
        locationId: uniqueID(),
        title: "Godley, Texas",
        streetAddress: "7701 Hwy 171, Suite F",
        cityAddress: "Godley, TX 76044",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "817-231-7416",
        faxNumber: "817-506-4383",
        directionsLinkURL: "https://www.google.com/maps/dir//7701+TX-171,+Godley,+TX+76044",
        coordinates: { lat: 32.43921620448918, lng: -97.52006571555434 },
        zoom: 14,
    },
    {
        locationId: uniqueID(),
        title: "Tyler, Texas",
        streetAddress: "1020 Asher Way Ste. 400",
        cityAddress: "Tyler, TX 75703",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "903-871-7577",
        faxNumber: "903-265-8266",
        directionsLinkURL: "https://www.google.com/maps/dir//1020+Asher+Wy,+Tyler,+TX+75703",
        coordinates: { lat: 32.27904700998618, lng: -95.3146282075228 },
        zoom: 14,
    },
    {
        locationId: uniqueID(),
        title: 'Cleburne, Texas',
        streetAddress: '131 S. Westmeadow Dr. #200',
        cityAddress: 'Cleburne, TX 76033',
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: '817-774-4004',
        faxNumber: '817-755-1712',
        directionsLinkURL: 'https://www.google.com/maps/dir//Truly+Title,+Inc.,+131+S+Westmeadow+Dr+%23200,+Cleburne,+TX+76033',
        coordinates: { lat: 32.3357491498749, lng: -97.41689759869458 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: 'Burleson Main Office, Texas',
        streetAddress: '101 NW Ellison Street, Unit 105',
        cityAddress: 'Burleson, TX 76028',
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: '817-394-2364',
        faxNumber: '817-755-0977',
        directionsLinkURL: 'https://www.google.com/maps/dir//101+NW+Ellison+St+%23105,+Burleson,+TX+76028',
        coordinates: { lat: 32.54157, lng: -97.32661 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: 'McKinney, Texas',
        streetAddress: '5881 Virginia Parkway, Suite 200',
        cityAddress: 'McKinney, TX 75071',
        tollFreeNumberVersion: '888-488-7859',
        tollFreeTrulyVersion: '888-4U TRULY',
        officeNumberDirect: '469-343-4544',
        faxNumber: '469-732-3332',
        directionsLinkURL: 'https://www.google.com/maps/dir//Truly+Title,+Inc.,+Virginia+Parkway,+McKinney,+TX,+USA',
        coordinates: { lat: 33.19785366650714, lng: -96.69606605847457 },
        zoom: 14,
    },
    {
        locationId: uniqueID(),
        title: 'Longview, Texas',
        streetAddress: '3827 N US Hwy 259',
        cityAddress: 'Longview, TX 75605',
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: '903-781-5396',
        faxNumber: '903-347-3964',
        directionsLinkURL: 'https://www.google.com/maps/dir/33.2327147,-97.1361087/3827+US-259,+Longview,+TX+75605',
        coordinates: { lat: 32.56421667342888, lng: -94.73375426709521 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: 'The Woodlands Office',
        streetAddress: '2829 Technology Forest Blvd Suite 400',
        cityAddress: 'The Woodlands, Texas 77381',
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: '281-676-3879',
        faxNumber: '254-765-2651',
        directionsLinkURL: 'https://www.google.com/maps/dir//2829+Technology+Forest+Blvd,+The+Woodlands,+TX+77381',
        coordinates: { lat: 30.176604420261945, lng: -95.48130376596728 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: "Cedar Park / North Austin, Texas",
        streetAddress: "2901 Caballo Ranch Blvd, Suite 5C",
        cityAddress: "Cedar Park, TX 78641",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "512-696-1575",
        faxNumber: "737-295-1482",
        directionsLinkURL: "https://www.google.com/maps/dir//2901+Caballo+Ranch+Blvd+%235c,+Leander,+TX+78641",
        coordinates: { lat: 30.548189244885506, lng: -97.79120046589253 },
        zoom: 14,
        appointmentBool: true
    },
    {
        locationId: uniqueID(),
        title: "Round Rock, Texas",
        streetAddress: "1717 North Mays Street",
        cityAddress: "Round Rock, TX 78664",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "512-279-4705",
        faxNumber: "737-295-1482",
        directionsLinkURL: "https://www.google.com/maps/dir//1717+N+Mays+St,+Round+Rock,+TX+78664",
        coordinates: { lat: 30.525561151249182, lng: -97.68619428993787 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: "Katy, Texas",
        streetAddress: "1575 W. Grand Parkway S. Suite 100",
        cityAddress: "Katy, Tx 77494",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "281-864-4416",
        faxNumber: "832-535-1265",
        directionsLinkURL: "https://www.google.com/maps/dir//1575+W+Grand+Pkwy+S,+Katy,+TX+77494",
        coordinates: { lat: 29.757833555830494, lng: -95.77481594556947 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: "Horseshoe Bay, Texas",
        streetAddress: "100 Bunny Run Suite 100",
        cityAddress: "Horseshoe Bay, TX 78657",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "512-288-4214",
        faxNumber: "817-382-2785",
        directionsLinkURL: "https://www.google.com/maps/dir//100+Bunny+Run+Ln,+Horseshoe+Bay,+TX+78657",
        coordinates: {lat: 30.544780508599167, lng: -98.34877165709554},
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: "Rockwall, Texas",
        streetAddress: "25 Noble Court",
        cityAddress: "Heath, TX 75032",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "469-656-5420",
        faxNumber: "469-383-8835",
        directionsLinkURL: "https://www.google.com/maps/dir//25+Noble+Ct,+Heath,+TX+75032",
        coordinates: {lat: 32.8612024815479, lng: -96.43798286499175},
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: "Upper Kirby",
        streetAddress: "2601 Westheimer Rd. Ste C-240",
        cityAddress: "Houston, TX 77098",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "713-997-6748",
        faxNumber: "832-802-7140",
        directionsLinkURL: "https://www.google.com/maps/dir//2601+Westheimer+Rd+Suite+C-240,+Houston,+TX+77098",       
        coordinates: {lat: 29.741984841917798, lng: -95.41956478058773},
        zoom: 14,
    },
    {
        locationId: uniqueID(),
        title: "Salt Lake City Metro",
        streetAddress: "6965 South Union Park Center, Ste. 180",
        cityAddress: "Cottonwood Heights, UT 84047",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "801-996-7456",
        faxNumber: "801-877-9512",
        directionsLinkURL: "https://www.google.com/maps/dir//Truly+Title,+Inc.,+South+Union+Park+Center,+Cottonwood+Heights,+UT",
        coordinates: { lat: 40.62520590238256, lng: -111.85547741516046 },
        zoom: 14,
    },
    {
        locationId: uniqueID(),
        title: "Spanish Fork, Utah",
        streetAddress: "65 West 200 North, Suite 1",
        cityAddress: "Spanish Fork, UT 84660",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "385-200-3552",
        faxNumber: "801-877-9501",
        directionsLinkURL: "https://www.google.com/maps/dir//Truly+Title,+Inc.,+West+200+North,+Spanish+Fork,+UT",
        coordinates: { lat: 40.112261, lng: -111.656049 },
        zoom: 14,
    },
    {
        locationId: uniqueID(),
        title: "West Jordan, Utah",
        streetAddress: "9089 South 1300 West, #120",
        cityAddress: "West Jordan, UT 84088",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "385-215-8480",
        faxNumber: "385-685-4002",
        directionsLinkURL: "https://www.google.com/maps/dir//Truly+Title,+Inc.,+1300+West+%23120,+West+Jordan,+UT",
        coordinates: { lat: 40.586200034049526, lng: -111.92700439746903 },
        zoom: 14,
    },
    {
        locationId: uniqueID(),
        title: "Sugar House, Utah",
        streetAddress: "2180 S 1300 E, STE 130",
        cityAddress: "Salt Lake City, UT 84106",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "801-505-0178",
        faxNumber: "385-327-0317",
        directionsLinkURL: "https://www.google.com/maps/dir//2180+S+1300+E+%23130,+Salt+Lake+City,+UT+84106",
        coordinates: { lat: 40.72395876447742, lng: -111.85456010896748 },
        zoom: 14,
    },
    {
        locationId: uniqueID(),
        title: "Vernal, Utah",
        streetAddress: "1680 West Highway 40, STE 108",
        cityAddress: "Vernal, Utah 84078",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY ",
        officeNumberDirect: "435-261-2253",
        faxNumber: "435-775-2390",
        directionsLinkURL: "https://www.google.com/maps/dir//1680+US-40+Suite+108,+Vernal,+UT+84078",
        coordinates: { lat: 40.44325827837256, lng: -109.56097154432621 },
        zoom: 14,
    },
    {
        locationId: uniqueID(),
        title: "South Ogden, Utah",
        streetAddress: "1481 E 5600 S, STE E101",
        cityAddress: "South Ogden, UT 84403",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY ",
        officeNumberDirect: "385-205-5560",
        faxNumber: "385-327-0158",
        directionsLinkURL: "https://www.google.com/maps/dir//1481+E+5600+S+e101,+Ogden,+UT+84403",
        coordinates: { lat: 41.16177997889241, lng: -111.94146897314518 },
        zoom: 14,
    },
    {
        locationId: uniqueID(),
        title: "St. George, UT 84770",
        streetAddress: "491 North Bluff Street, Unit 204",
        cityAddress: "St. George, UT 84770",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY ",
        officeNumberDirect: "435-817-9388",
        faxNumber: "385-685-4002",
        directionsLinkURL: "https://www.google.com/maps/dir//491+N+Bluff+St+Suite+204,+St.+George,+UT+84770",
        coordinates: { lat: 37.22327991317299, lng: -113.61896277166296 },
        zoom: 14,
    },
    {
        locationId: uniqueID(),
        title: 'Palm Beach Gardens, Florida',
        streetAddress: '5090 PGA Boulevard, Suite 300',
        cityAddress: 'Palm Beach Gardens, FL 33418',
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: '561-370-7470',
        faxNumber: '561-898-2224',
        directionsLinkURL: 'https://www.google.com/maps/dir//Truly+Title+-+Palm+Beach,+5090+PGA+Boulevard+Suite+300,+Palm+Beach+Gardens,+FL+33418',
        coordinates: { lat: 26.838144027958347, lng: -80.11515886706862 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: 'Stuart, Florida',
        streetAddress: '759 SW Federal Hwy., Suite 313',
        cityAddress: 'Stuart, Florida 34994',
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: '772-919-3670',
        faxNumber: '772-999-6185',
        directionsLinkURL: 'https://www.google.com/maps/dir//759+SW+Federal+Hwy+%23206,+Stuart,+FL+34994',
        coordinates: { lat: 27.193096679444764, lng: -80.25556740156065 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: 'Wellington, Florida',
        streetAddress: '12773 West Forest Hill Blvd., Suite 1203',
        cityAddress: 'Wellington, FL 33414',
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: '561-370-7470',
        faxNumber: '561-898-2224',
        directionsLinkURL: 'https://www.google.com/maps/dir//12773+Forest+Hill+Blvd+%231203,+Wellington,+FL+33414',
        coordinates: { lat: 26.671079704381988, lng: -80.24788912013835 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: 'Lakeland, Florida',
        streetAddress: '20 Lake Wire Dr, Ste 152',
        cityAddress: 'Lakeland, FL 33815',
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: '863-248-0699',
        faxNumber: '863-271-7287',
        directionsLinkURL: 'https://www.google.com/maps/dir//20+Lake+Wire+Dr+%23152,+Lakeland,+FL+33815',
        coordinates: { lat: 28.045109953413665, lng: -81.95985192226959 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: "Port St. Lucie, Florida",
        streetAddress: "1100 SW St Lucie W Blvd, STE 100",
        cityAddress: "Port St. Lucie, FL 34986",
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: "772-905-4388",
        faxNumber: "772-410-3156",
        directionsLinkURL: "https://www.google.com/maps/dir//Truly+Title+-+Port+St.+Lucie,+1100+St+Lucie+W+Blvd+%23+100,+Port+St.+Lucie,+FL+34986",
        coordinates: { lat: 27.31618169487603, lng: -80.38539655926292 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: 'Gilbert, Arizona',
        streetAddress: '335 E Germann Rd., Ste. 110',
        cityAddress: 'Gilbert, AZ 85297',
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: '480-240-5614',
        faxNumber: '480-771-5564',
        directionsLinkURL: 'https://www.google.com/maps/dir//335+E+Germann+Rd+STE+110,+Gilbert,+AZ+85298',
        coordinates: { lat: 33.27700, lng: -111.78445 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: 'Tucson, Arizona',
        streetAddress: '7400 N Oracle Road, STE 150',
        cityAddress: 'Tucson, AZ 85704',
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: '520-352-7455',
        faxNumber: '520-253-8734',
        directionsLinkURL: 'https://www.google.com/maps/dir//7400+N+Oracle+Rd,+Tucson,+AZ+85704',
        coordinates: { lat: 32.34208819432788, lng: -110.97630280772543 },
        zoom: 14
    },
    {
        locationId: uniqueID(),
        title: 'Jackson, Wyoming',
        streetAddress: '270 W Pearl Ave Ste. 104',
        cityAddress: 'Jackson, WY 83001',
        tollFreeNumberVersion: "888-488-7859",
        tollFreeTrulyVersion: "888-4U TRULY",
        officeNumberDirect: '307-733-3153',
        faxNumber: '',
        directionsLinkURL: 'https://www.google.com/maps/dir//270+W+Pearl+Ave+APT+104,+Jackson,+WY+83001',
        coordinates: { lat: 43.47869861551599, lng: -110.76617143220881 },
        zoom: 14
    },
]
